'use client';

import Button from '@/components/shared/button/Button';
import { Link } from '@/lib/router-events/patch-router/link';
import React from 'react';
import type { HTMLAttributeReferrerPolicy } from 'react';
import type { Icon } from '@/components/shared/button/types';

interface Props {
  text: string;
  url: string;
  appearance: 'filled' | 'lined' | 'ghost';
  isBlock?: boolean;
  target?: string;
  className?: string;
  icon?: Icon | JSX.Element;
  onClick?: () => void;
  referrerPolicy?: HTMLAttributeReferrerPolicy;
}

/**
 * LinkButton component: link (a tag) that looks like a button
 */
function LinkButton({
  text,
  url,
  appearance,
  isBlock,
  target,
  className,
  icon,
  onClick,
  referrerPolicy
}: Props): JSX.Element {
  return (
    <Link href={url} target={target} onClick={onClick} referrerPolicy={referrerPolicy}>
      <Button
        text={text}
        aria-label={text}
        isBlock={isBlock}
        appearance={appearance}
        className={className}
        icon={icon}
      />
    </Link>
  );
}

export default LinkButton;
